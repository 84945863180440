
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  setup() {
    const { t } = useI18n();

    const email = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const http = /(http(s?)):\/\//i;
    const url =
      /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;

    const currentMember = ref(0);

    const defineLink = (link: string) => {
      link = link.replace("&#64;", "@");
      if (email.test(link)) return `mailto:${link}`;
      else if (!http.test(link)) return `https://${link}`;
      else return link;
    };

    const defineLinkText = (link: string) => {
      link = link.replace("&#64;", "@");
      if (email.test(link)) return link.replace("mailto:", "");
      else if (
        url.test(link) &&
        !link.replace("https://", "").replace("http://", "").includes("/")
      )
        return link.replace("https://", "").replace("http://", "");
      else return "@" + link.slice(link.lastIndexOf("/") + 1, link.length);
    };

    return {
      t,
      defineLink,
      defineLinkText,
      currentMember,
    };
  },
});
