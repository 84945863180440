
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  setup() {
    const { t } = useI18n();

    const numberOfInterestsInLine = 10;

    const nums = new Set();
    while (nums.size !== numberOfInterestsInLine * 3) {
      nums.add(Math.floor(Math.random() * 99));
    }

    const interests = ref([
      [...nums].slice(0, numberOfInterestsInLine),
      [...nums].slice(numberOfInterestsInLine, numberOfInterestsInLine * 2),
      [...nums].slice(numberOfInterestsInLine * 2, numberOfInterestsInLine * 3),
    ]);

    return {
      t,
      interests,
    };
  },
});
