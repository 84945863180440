
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const currentYear = ref(new Date().getFullYear());

    return {
      currentYear,
      t,
    };
  },
});
