{
  "title": "Inkast • Your soulmate is nearby",
  "Home": {
    "nav": {
      "product": "Product",
      "team": "Team",
      "invest": "Investors",
      "donate": "Help the project",
      "language": "RU",
      "join": "Join",
      "support": "Support"
    },
    "greeting": {
      "hello": "Welcome <br> to&nbsp;Inkast",
      "slogan": "Social network for soulmate search"
    },
    "selection": {
      "title": "Find people according to&nbsp;interests, goals, favorite activities and even by&nbsp;character",
      "join": "Join"
    },
    "couple": {
      "title": "Somewhere on&nbsp;Earth, there is&nbsp;a&nbsp;person who is&nbsp;the right match for you",
      "additional": "Perhaps, you do&nbsp;not know about each other&rsquo;s existence yet"
    },
    "intrestings": {
      "title": "Imagine <br> both of&nbsp;you...",
      "additional": "Interests",
      "bubles": [
        {
          "image": "cube",
          "text": "Into board games"
        },
        {
          "image": "notebook",
          "text": "code at night"
        },
        {
          "image": "gamepad",
          "text": "play computer games"
        },
        {
          "image": "instruments",
          "text": "work flat out"
        },
        {
          "image": "palette",
          "text": "dream of becoming artists"
        },
        {
          "image": "music",
          "text": "have similar musical tastes"
        },
        {
          "image": "astrology",
          "text": "into astrology"
        },
        {
          "image": "anime",
          "text": "watch anime"
        },
        {
          "image": "run",
          "text": "enjoy running"
        },
        {
          "image": "freedom",
          "text": "dream of visiting New York"
        },
        {
          "image": "boom",
          "text": "mad about Naruto"
        },
        {
          "image": "corgi",
          "text": "raise a corgi"
        },
        {
          "image": "china",
          "text": "enjoy Chinese"
        },
        {
          "image": "apple",
          "text": "invest in Apple"
        },
        {
          "image": "cash",
          "text": "love the money"
        },
        {
          "image": "needle",
          "text": "buy patches in Daijebu"
        },
        {
          "image": "detective",
          "text": "re-watch Sherlock for the New Year"
        },
        {
          "image": "meditation",
          "text": "meditate"
        },
        {
          "image": "lotus",
          "text": "do yoga"
        },
        {
          "image": "badminton",
          "text": "play badminton"
        },
        {
          "image": "abacus",
          "text": "can’t solve the Navier-Stokes equation"
        },
        {
          "image": "notes",
          "text": "detest solfeggio"
        },
        {
          "image": "tiktok",
          "text": "make videos on TikTok"
        },
        {
          "image": "starsky",
          "text": "try to unravel the mysteries of the Universe"
        },
        {
          "image": "magnifier",
          "text": "investigate a conspiracy theory"
        },
        {
          "image": "alembic",
          "text": "study proteomics"
        },
        {
          "image": "icecream",
          "text": "don’t like sweets"
        },
        {
          "image": "donut",
          "text": "like sweets"
        },
        {
          "image": "teapot",
          "text": "drink tea without sugar"
        },
        {
          "image": "potato",
          "text": "addicted to crisps"
        },
        {
          "image": "chocolate",
          "text": "buy Snickers by the kilos"
        },
        {
          "image": "leaf",
          "text": "have a box of medicines at home"
        },
        {
          "image": "popcorn",
          "text": "get everyone hooked on the «Attack of the Titans» "
        },
        {
          "image": "bag",
          "text": "like spontaneous trips"
        },
        {
          "image": "bento",
          "text": "spend last money on rolls"
        },
        {
          "image": "thread",
          "text": "sew clothes"
        },
        {
          "image": "skull",
          "text": "do extreme sports"
        },
        {
          "image": "snowboard",
          "text": "go snowboarding every winter"
        },
        {
          "image": "work",
          "text": "participate in hackathons"
        },
        {
          "image": "books",
          "text": "write books"
        },
        {
          "image": "soap",
          "text": "make soap"
        },
        {
          "image": "tvbox",
          "text": "watch a depilation videos"
        },
        {
          "image": "skateboard",
          "text": "ride a skateboard"
        },
        {
          "image": "smile",
          "text": "dream of doing nothing"
        },
        {
          "image": "friedegg",
          "text": "study haute cuisine"
        },
        {
          "image": "borido",
          "text": "love borscht with sour cream"
        },
        {
          "image": "paella",
          "text": "dream of delicious meal"
        },
        {
          "image": "parachute",
          "text": "fantasize about skydiving"
        },
        {
          "image": "wolf",
          "text": "dream about living with a large dog"
        },
        {
          "image": "museum",
          "text": "enjoy architecture"
        },
        {
          "image": "pickaxe",
          "text": "delighted with Minecraft"
        },
        {
          "image": "star",
          "text": "excited about Brawl Stars"
        },
        {
          "image": "pierog",
          "text": "can eat khinkali and khachapuri daily"
        },
        {
          "image": "kitty",
          "text": "cat fans"
        },
        {
          "image": "sunrise",
          "text": "like to walk in sunny weather"
        },
        {
          "image": "subway",
          "text": "prefer ride the subway"
        },
        {
          "image": "walk",
          "text": "able to walk 40 km in one day"
        },
        {
          "image": "scooter",
          "text": "crazy about scooters"
        },
        {
          "image": "incredible",
          "text": "get through culture shock"
        },
        {
          "image": "box",
          "text": "have a container with containers"
        },
        {
          "image": "hanafuda",
          "text": "love to read tarot"
        },
        {
          "image": "theatre",
          "text": "cannot live without a theater"
        },
        {
          "image": "postlove",
          "text": "dream of being css to his html"
        },
        {
          "image": "kiss",
          "text": "know the meaning of \"gamarjoba\""
        },
        {
          "image": "python",
          "text": "pythonists to the core"
        },
        {
          "image": "c-plus-plus",
          "text": "detest C++"
        },
        {
          "image": "snowflake",
          "text": "admire skating"
        },
        {
          "image": "faceinclouds",
          "text": "visit a psychologist together"
        },
        {
          "image": "brain",
          "text": "go to a psychiatrist together"
        },
        {
          "image": "shower",
          "text": "enjoy singing in the shower"
        },
        {
          "image": "wine",
          "text": "prefer premium alcohol"
        },
        {
          "image": "moai",
          "text": "look for the meaning of life"
        },
        {
          "image": "tea",
          "text": "drink 6 cups of coffee a-day"
        },
        {
          "image": "note",
          "text": "experienced BeOn"
        },
        {
          "image": "game",
          "text": "like role-playing"
        },
        {
          "image": "melody",
          "text": "were fans of the Glee Cast"
        },
        {
          "image": "tape",
          "text": "watched almost all the series on Netflix"
        },
        {
          "image": "basketball",
          "text": "replayed Mass Effect several times"
        },
        {
          "image": "movie",
          "text": "write movie reviews"
        },
        {
          "image": "desktop",
          "text": "work as a designer"
        },
        {
          "image": "swap",
          "text": "manage tasks"
        },
        {
          "image": "city",
          "text": "addicted to urbanism"
        },
        {
          "image": "van",
          "text": "ride the cable cars"
        },
        {
          "image": "magister",
          "text": "university students"
        },
        {
          "image": "shopping",
          "text": "buy up sugar"
        },
        {
          "image": "finances",
          "text": "follow trends"
        },
        {
          "image": "chartdecrease",
          "text": "monitor the NFT market"
        },
        {
          "image": "bitcoin",
          "text": "trade crypto"
        },
        {
          "image": "hole",
          "text": "place tiles"
        },
        {
          "image": "taxi",
          "text": "prefer economy class"
        },
        {
          "image": "headphones",
          "text": "listen to Oxxxymiron"
        },
        {
          "image": "piano",
          "text": "do the beats"
        },
        {
          "image": "banana",
          "text": "love bananas"
        },
        {
          "image": "sun",
          "text": "wait for summer"
        },
        {
          "image": "car",
          "text": "remember about the oil change"
        },
        {
          "image": "wheel",
          "text": "like to pump tires"
        },
        {
          "image": "shoes",
          "text": "resell sneakers"
        },
        {
          "image": "book",
          "text": "read Nietzsche"
        },
        {
          "image": "faucet",
          "text": "can fix the faucet"
        },
        {
          "image": "couch",
          "text": "like to move furniture"
        }
      ]
    },
    "rare": {
      "title": "Only a&nbsp;rare chance could bring you together <br> The time is&nbsp;<span>now</span>",
      "additional": "You are a&nbsp;combination of&nbsp;different tastes, interests, and outlooks on&nbsp;life. Finding a&nbsp;similar person is&nbsp;too difficult, but it&nbsp;is&nbsp;not a&nbsp;problem for&nbsp;us"
    },
    "howItWorks": {
      "title": "How it&nbsp;works",
      "additional": "Scroll through the feed with diverse content, like the <span style='color: #C06CE8'>memes, movies, books, computer games, favorite music...</span> There are many content categories, and they are constantly being updated!",
      "description": "We&nbsp;strive to&nbsp;take into account even the rarest interests so&nbsp;that you can definitely find your soulmate. But if&nbsp;your interest is&nbsp;still not represented&nbsp;&mdash; write to&nbsp;us about it&nbsp;on&nbsp;<a href=\"https://t.me/inkast_me/511\" target=\"_blank\" style=\"color: #C06CE8; text-decoration: none\">Telegram!</a>"
    },
    "notEasy": {
      "title": "It&nbsp;is&nbsp;not that easy",
      "additional": "We&nbsp;have created our own machine learning algorithms that allow you to&nbsp;project your digital twin into the Web. These &laquo;metacopies&raquo; have certain preferences and character.",
      "description": "Digital twins are compared by&nbsp;additional algorithms. As&nbsp;a&nbsp;result, the most fitting people are matched to&nbsp;you."
    },
    "algorithm": {
      "title": "Algorithm implementation",
      "step-1": "Inkast creates a&nbsp;digital copy of&nbsp;a&nbsp;person based on&nbsp;his metadata. <br> <br>This copy has your interests and character",
      "step-2": "Machine learning algorithms compare digital portraits of&nbsp;different people to&nbsp;each other, and then matrix of&nbsp;the best combinations of&nbsp;interests and character is&nbsp;generated",
      "step-3": "As&nbsp;a&nbsp;result, our service shows you a&nbsp;list of&nbsp;recommended soulmates"
    },
    "next": {
      "title": "What&rsquo;s next?",
      "description": "Soulmate is&nbsp;a&nbsp;kindred spirit person. A&nbsp;particularly strong bond is&nbsp;formed with them. Such a&nbsp;person could be&nbsp;your best friend, lover, partner or&nbsp;the most interesting companion. <br> <br>Your strong relationship would be&nbsp;due to&nbsp;a&nbsp;wide range of&nbsp;overlapping interests, views on&nbsp;life and the same worldview. With the most folks your paths through life can diverge. On&nbsp;the other side, with a&nbsp;soulmate you move side by&nbsp;side. That is&nbsp;why such a&nbsp;connectiion is&nbsp;one of&nbsp;the greatest values for people.",
      "image": "en"
    },
    "problems": {
      "title": "We&nbsp;provide an&nbsp;opportunity to&nbsp;solve current problems",
      "cards": [
        {
          "title": "Lack of&nbsp;time",
          "image": "time",
          "alt": "How to make acquaintances when lacking time",
          "description": "If&nbsp;you work hard every day and do&nbsp;not have time for dating, you will find a&nbsp;person with the same pace of&nbsp;life"
        },
        {
          "title": "Talking points",
          "image": "message",
          "alt": "Topics for conversations on a date",
          "description": "The search for topics for a&nbsp;conversation remains in&nbsp;the past, we&nbsp;have already selected the ideal pen pal for you"
        },
        {
          "title": "Loneliness",
          "image": "loneliness",
          "alt": "How to cope with loneliness",
          "description": "Because of&nbsp;the lack of&nbsp;common interests you can feel lonely even with lots of&nbsp;friends. Our service will help you to&nbsp;find a&nbsp;person with the same traits"
        },
        {
          "title": "Search for friends",
          "image": "friends",
          "alt": "How to find friends",
          "description": "Dating services only provide romantic offers. In&nbsp;contrast, our status systems will immediatly show what a&nbsp;person is&nbsp;looking for. A&nbsp;friend? A&nbsp;loved one? Or&nbsp;both?"
        }
      ],
      "join": "Join"
    },
    "show": {
      "title": "Inkast knows this person and is&nbsp;ready to&nbsp;introduce you to&nbsp;each other",
      "join": "Find a&nbsp;friend"
    },
    "team": {
      "title": "Team",
      "interactive": "Interactive",
      "members": [
      {
        "avatar": "Alena.png",
        "emoji": "paw",
        "name": "Alena Samarina",
        "post": "CEO &amp;&nbsp;Head Marketing",
        "preferredColor": "FFE5D6",
        "description":
          "Carried out projects for Mars, Danone, Nexign, Schlumberger, Gazprom Neft, Severstal and Rosatom <br><br>Multiple winner of&nbsp;case championships and hackathons",
        "employmentExp": "Work experience: 6&nbsp;years",
        "link": "Contact us: info&#64;inkast.me"
      },
      {
        "avatar": "Alexander.png",
        "emoji": "trackball",
        "name": "Alexander Greene",
        "post": "COO &amp;&nbsp;Product Designer",
        "preferredColor": "E7E7E9",
        "description":
          "Carried out projects for Yandex, VK, Gazprom, Skyeng and X5&nbsp;Group <br><br> Winner of&nbsp;the HSE Olympiad in&nbsp;the field of&nbsp;design and multiple winner of&nbsp;hackathons",
        "employmentExp": "Work experience: 6&nbsp;years",
        "link": "Contact us: info&#64;inkast.me"
      },
      {
        "avatar": "Natalia.png",
        "emoji": "noevilmonkey",
        "name": "Natalia Maltseva",
        "post": "CTO &amp;&nbsp;Backend developer",
        "preferredColor": "E0C49E",
        "description":
          "Built a&nbsp;racing car in&nbsp;the electrics department.<br><br>The recipient of the UMNIK grant. Winner of hackathons and case championships",
        "employmentExp": "Work experience: 5&nbsp;years",
        "link": "Contact us: info&#64;inkast.me"
      },
      {
        "avatar": "Platon.png",
        "emoji": "vulcan",
        "name": "Platon Lapp",
        "post": "Front-end developer",
        "preferredColor": "C7C789",
        "description": "Just a cool guy",
        "employmentExp": "Work experience: 5&nbsp;years",
        "link": "Contact us: info&#64;inkast.me"
      },
      {
        "avatar": "Ulyana.png",
        "emoji": "tiger",
        "name": "Ulyana Salamatova",
        "post": "Android Developer",
        "preferredColor": "E6F7A2",
        "description": "Code on&nbsp;Kotlin",
        "employmentExp": "Work experience: 3&nbsp;year",
        "link": "Contact us: info&#64;inkast.me"
      },
      {
        "avatar": "Anna.png",
        "emoji": "victory",
        "name": "Anna Grokhotova",
        "post": "Marketing specialist",
        "preferredColor": "FFE5D6",
        "description":
          "Carry out projects in&nbsp;the field of&nbsp;neurotechnology <br><br> Know how our brain works and what people need",
        "employmentExp": "Work experience: 5&nbsp;years",
        "link": "Contact us: info&#64;inkast.me"
      },
      {
        "avatar": "NataliaT.png",
        "emoji": "smile",
        "name": "Natalia Trusova",
        "post": "Data Analyst",
        "preferredColor": "94ADE8",
        "description":
          "Participation in&nbsp;numerous projects for major firms as&nbsp;part of&nbsp;a&nbsp;consulting team, work in&nbsp;a&nbsp;financial organization, and development of&nbsp;new algorithms to&nbsp;help businesses thrive",
        "employmentExp": "Work experience: 3&nbsp;years",
        "link": "Contact us: info&#64;inkast.me"
      },
      {
        "avatar": "Sofia.png",
        "emoji": "sparkles",
        "name": "Sofia Bolokhovskaya",
        "post": "Social Media Manager, Marketer",
        "preferredColor": "F8DFBE",
        "description": "Executed projects for a&nbsp;consulting agency, a&nbsp;large industrial enterprise, and retail networks.<br><br>Holds a&nbsp;degree in&nbsp;Advertising and Public Relations",
        "employmentExp": "Work experience: 2&nbsp;years",
        "link": "Contact us: info&#64;inkast.me"
      },
      {
        "avatar": "Alan.png",
        "emoji": "goggles",
        "name": "Alan Gasiev",
        "post": "I am engaged in product quality control",
        "preferredColor": "f7f7f7",
        "description": "This involves defect detection, establishing system requirements, and analyzing its behavior",
        "employmentExp": "Work experience: 3&nbsp;years",
        "link": "Contact us: info&#64;inkast.me"
      },
      {
        "avatar": "Andrey.png",
        "emoji": "winking-face",
        "name": "Andrey Shabunin",
        "post": "Android Developer",
        "preferredColor": "EDECE3",
        "description": "Workaholic for life<br><br>I code in Kotlin/Java/C++",
        "employmentExp": "Work experience: 3&nbsp;years",
        "link": "Contact us: info&#64;inkast.me"
      },
      {
        "avatar": "Misha.png",
        "emoji": "microscope",
        "name": "Misha Sanzhak",
        "post": "DevOps",
        "preferredColor": "CED6E8",
        "description": "I&nbsp;write a&nbsp;lot of&nbsp;code and not just YAML. I&nbsp;love mechanical keyboards, powerlifting, and systems",
        "employmentExp": "Work experience: over 6&nbsp;years",
        "link": "Contact us: info&#64;inkast.me"
      }
    ]
    },
    "follow": {
      "title": "Let&rsquo;s be <br> friends!",
      "additional": "Subscribe for updates so&nbsp;you do&nbsp;not miss the launch",
      "inputs": {
        "name": "Name",
        "nameError": "Enter the name again, please",
        "email": "E-mail",
        "emailError": "Incorrect email, try again",
        "confPolicy": "I&nbsp;agree with the<a href='/privacy' target='_blank'> privacy policy</a>",
        "confError": ""
      },
      "popup": {
        "title": "Thank you for subscribing",
        "description": "We&nbsp;promise not to&nbsp;spam and only keep up&nbsp;to&nbsp;date with current events"
      },
      "join": "Join"
    },
    "footer": {
      "with": "With ",
      "love": " from Saint-Petersburg",
      "termsAndPolicy": "Terms &amp;&nbsp;Policies"
    }
  },
  "Donate": {
    "title": "Financial support",
    "problems": "Have a&nbsp;problem?",
    "variants": {
      "viaInternet": {
        "title": "Wire transfer",
        "description": "Transfer of&nbsp;funds to&nbsp;the joint account of&nbsp;Alena Samarina and the Inkast Team"
      },
      "vk": {
        "title": "VK Donuts",
        "description": "Make a&nbsp;monthly donation using&nbsp;VK donuts system"
      }
    }
  },
  "404": {
    "description": "Page not found",
    "main": "Back to&nbsp;Home"
  },
  "Investors": {
    "hero": {
      "CEO": "Alena Samarina",
      "CTO": "Natalia Maltseva",
      "COO": "Alexander Greene",
      "contacts": "Contact us"
    },
    "rootcase": {
      "title": "Prime cause",
      "leadtext": "Initially, the idea of&nbsp;Inkast came from the observation that people in&nbsp;dating services cannot find a&nbsp;partner for a&nbsp;long-term relationship",
      "text": "It&nbsp;has been hypothesized that current market proposals do not cover the need to&nbsp;find a&nbsp;couple. In&nbsp;order to&nbsp;test&nbsp;it, the survey was conducted",
      "button": "Open a presentation • EN"
    },
    "mvp": {
      "mvpmap": {
        "image": "en"
      },
      "footnote": "Some of&nbsp;the functions are confidentional and are not specified here",
      "legenda01": "MVP",
      "legenda02": "Improve after MVP"
    },
    "conclusions": {
      "title": "Conclusions",
      "description": "Inkast is&nbsp;a&nbsp;service that has no&nbsp;analogues in&nbsp;the market. It&nbsp;helps the users:<ul><li>find their soulmate, friend or&nbsp;conversationalist with similar interests</li><li>solve challenges of&nbsp;relationships of&nbsp;our time</li></ul>"
    }
  }
}