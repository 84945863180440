
import { defineComponent } from "vue";
import Button from "@/components/Button.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: {
    Button,
  },
  setup() {
    const { t } = useI18n();

    return {
      t,
    };
  },
});
