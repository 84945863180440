
import { defineComponent, onMounted } from "vue";
import Navbar from "@/components/Navbar.vue";

import Greeting from "@/components/Home/Greeting.vue";
import Selection from "@/components/Home/Selection.vue";
import Couple from "@/components/Home/Couple.vue";
import Intrestings from "@/components/Home/Intrestings.vue";
import Rare from "@/components/Home/Rare.vue";
import HowItWorks from "@/components/Home/HowItWorks.vue";
import NotEasy from "@/components/Home/NotEasy.vue";
// import Algorithm from "@/components/Home/Algorithm.vue";
import Next from "@/components/Home/Next.vue";
import Problems from "@/components/Home/Problems.vue";
import Show from "@/components/Home/Show.vue";
import Team from "@/components/Home/Team.vue";
import Follow from "@/components/Home/Follow.vue";
import Footer from "@/components/Home/Footer.vue";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "Home",
  components: {
    Navbar,
    Greeting,
    Selection,
    Couple,
    Intrestings,
    Rare,
    HowItWorks,
    NotEasy,
    Problems,
    Show,
    Team,
    Follow,
    Footer,
    Next,
  },

  setup() {
    const route = useRoute();

    onMounted(() => {
      if (route.hash) {
        const element = document.querySelector(route.hash);
        if (element) {
          const y = element?.getBoundingClientRect().top + window.scrollY - 48;

          window.scrollTo({ top: y, behavior: "smooth" });
        }
      }
    });
  },
});
