import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-20178a01"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "popup" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "popup__wrapper" }
const _hoisted_5 = ["src", "alt"]
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "popupWrapper",
    onClick: _cache[1] || (_cache[1] = () => _ctx.$emit('close'))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      }, [
        _createElementVNode("img", {
          src: require('@/assets/close.svg'),
          class: "close__btn",
          alt: "Close"
        }, null, 8, _hoisted_2)
      ]),
      _createElementVNode("h2", {
        innerHTML: _ctx.t('Home.follow.popup.title')
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("img", {
          src: require(`@/assets/team/avatars/${_ctx.randomMember}.png`),
          alt: _ctx.randomMember
        }, null, 8, _hoisted_5),
        _createElementVNode("p", {
          innerHTML: _ctx.t('Home.follow.popup.description')
        }, null, 8, _hoisted_6)
      ])
    ])
  ]))
}