import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cc90a3fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content__wrapper" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "nav__additional" }
const _hoisted_4 = ["src"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { class: "nav__links-wrapper" }
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = {
  key: 0,
  class: "modal"
}
const _hoisted_10 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("nav", {
      class: _normalizeClass(["nav__wrapper", { nav__wrapper_modal: _ctx.openModal }]),
      ref: "header",
      onMouseenter: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.mouseEnterHandler && _ctx.mouseEnterHandler(...args)))
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_router_link, {
          to: "/",
          class: "nav__logo"
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              src: require('@/assets/logo.svg'),
              alt: "InKast"
            }, null, 8, _hoisted_2)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_router_link, {
            to: "/#follow",
            class: "nav__join",
            innerHTML: _ctx.t('Home.nav.join'),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.scrollIntoView('#follow')))
          }, null, 8, ["innerHTML"]),
          _createVNode(_component_router_link, {
            to: "/donate",
            class: "nav__link",
            innerHTML: _ctx.t('Home.nav.donate')
          }, null, 8, ["innerHTML"]),
          _createElementVNode("img", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openModal = !_ctx.openModal)),
            class: "nav__burger",
            src: require(`@/assets/${_ctx.openModal ? 'close' : 'burger'}.svg`),
            alt: "Изначально, наш проект назывался МетаСеть:)"
          }, null, 8, _hoisted_4),
          _createElementVNode("div", {
            class: "nav__language",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.switchLang && _ctx.switchLang(...args))),
            innerHTML: _ctx.t('Home.nav.language')
          }, null, 8, _hoisted_5)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_router_link, {
            to: "/#product",
            class: "nav__link",
            innerHTML: _ctx.t('Home.nav.product'),
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.scrollIntoView('#product')))
          }, null, 8, ["innerHTML"]),
          _createVNode(_component_router_link, {
            to: "/#team",
            class: "nav__link",
            innerHTML: _ctx.t('Home.nav.team'),
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.scrollIntoView('#team')))
          }, null, 8, ["innerHTML"]),
          _createVNode(_component_router_link, {
            to: "/invest",
            class: "nav__link",
            innerHTML: _ctx.t('Home.nav.invest')
          }, null, 8, ["innerHTML"]),
          _createElementVNode("a", {
            href: "mailto:info@inkast.me",
            class: "nav__link",
            target: "_blank",
            innerHTML: _ctx.t('Home.nav.support')
          }, null, 8, _hoisted_7),
          _createElementVNode("div", {
            class: "nav__language",
            onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.switchLang && _ctx.switchLang(...args))),
            innerHTML: _ctx.t('Home.nav.language')
          }, null, 8, _hoisted_8)
        ])
      ])
    ], 34),
    _createVNode(_Transition, { name: "modal" }, {
      default: _withCtx(() => [
        (_ctx.openModal)
          ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createVNode(_component_router_link, {
                to: "/#product",
                class: _normalizeClass(["nav__link", { active: _ctx.route.hash == '#product' }]),
                innerHTML: _ctx.t('Home.nav.product'),
                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.scrollIntoView('#product')))
              }, null, 8, ["class", "innerHTML"]),
              _createVNode(_component_router_link, {
                to: "/#team",
                class: _normalizeClass(["nav__link", { active: _ctx.route.hash == '#team' }]),
                innerHTML: _ctx.t('Home.nav.team'),
                onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.scrollIntoView('#team')))
              }, null, 8, ["class", "innerHTML"]),
              _createVNode(_component_router_link, {
                to: "/invest",
                class: _normalizeClass(["nav__link", { active: _ctx.route.name == 'Invest' }]),
                innerHTML: _ctx.t('Home.nav.invest')
              }, null, 8, ["class", "innerHTML"]),
              _createVNode(_component_router_link, {
                to: "/donate",
                class: _normalizeClass(["nav__link", { active: _ctx.route.name == 'Donate' }]),
                innerHTML: _ctx.t('Home.nav.donate')
              }, null, 8, ["class", "innerHTML"]),
              _createElementVNode("a", {
                href: "mailto:info@inkast.me",
                class: "nav__link",
                target: "_blank",
                innerHTML: _ctx.t('Home.nav.support')
              }, null, 8, _hoisted_10),
              _createVNode(_component_router_link, {
                to: "/#follow",
                class: "nav__join",
                innerHTML: _ctx.t('Home.nav.join'),
                onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.scrollIntoView('#follow')))
              }, null, 8, ["innerHTML"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}