import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-396e6da1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home__selection" }
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h3", {
      innerHTML: _ctx.t('Home.selection.title')
    }, null, 8, _hoisted_2),
    _createVNode(_component_Button, {
      href: "#follow",
      innerHTML: _ctx.t('Home.selection.join')
    }, null, 8, ["innerHTML"])
  ]))
}