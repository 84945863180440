import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c0387ffa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home__intrestings" }
const _hoisted_2 = { class: "intrestings__text-wrapper" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "intrestings__more" }
const _hoisted_5 = { class: "intrestings__wrapper" }
const _hoisted_6 = { class: "wrapper__line" }
const _hoisted_7 = ["src", "alt"]
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = { class: "wrapper__line wrapper__interest_doubler" }
const _hoisted_10 = ["src", "alt"]
const _hoisted_11 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h3", {
        innerHTML: _ctx.t('Home.intrestings.title')
      }, null, 8, _hoisted_3),
      _createElementVNode("div", _hoisted_4, " +95 " + _toDisplayString(_ctx.t("Home.intrestings.additional")), 1)
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.interests, (array) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "wrapper__line-fixed",
          key: array
        }, [
          _createElementVNode("div", _hoisted_6, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(array, (interest) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "wrapper__interest",
                key: interest
              }, [
                _createElementVNode("img", {
                  src: 
                require(`@/assets/emoji/${_ctx.t(
                  `Home.intrestings.bubles[${interest}].image`
                )}.png`)
              ,
                  alt: _ctx.t(`Home.intrestings.bubles[${interest}].text`)
                }, null, 8, _hoisted_7),
                _createElementVNode("span", {
                  innerHTML: _ctx.t(`Home.intrestings.bubles[${interest}].text`)
                }, null, 8, _hoisted_8)
              ]))
            }), 128))
          ]),
          _createElementVNode("div", _hoisted_9, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(array, (interest) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "wrapper__interest",
                key: interest
              }, [
                _createElementVNode("img", {
                  src: 
                require(`@/assets/emoji/${_ctx.t(
                  `Home.intrestings.bubles[${interest}].image`
                )}.png`)
              ,
                  alt: _ctx.t(`Home.intrestings.bubles[${interest}].text`)
                }, null, 8, _hoisted_10),
                _createElementVNode("span", {
                  innerHTML: _ctx.t(`Home.intrestings.bubles[${interest}].text`)
                }, null, 8, _hoisted_11)
              ]))
            }), 128))
          ])
        ]))
      }), 128))
    ])
  ]))
}