import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-66ed10a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "problems__sticky" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["src", "alt"]
const _hoisted_4 = { class: "problems__scroll" }
const _hoisted_5 = ["data-problem-id", "onMouseover"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "scroll__image" }
const _hoisted_9 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", {
    class: "home__problems",
    onScroll: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.scrollHandler && _ctx.scrollHandler(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h2", {
        innerHTML: _ctx.t('Home.problems.title')
      }, null, 8, _hoisted_2),
      _createElementVNode("img", {
        src: 
          require(`@/assets/cards/problems/${_ctx.t(
            `Home.problems.cards[${_ctx.currentProblem}].image`
          )}.png`)
        ,
        alt: _ctx.t(`Home.problems.cards[${_ctx.currentProblem}].title`)
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(4, (index) => {
        return _createElementVNode("div", {
          class: "scroll__wrapper",
          key: index,
          "data-problem-id": index - 1,
          onMouseover: ($event: any) => (_ctx.currentProblem = index - 1)
        }, [
          _createElementVNode("div", {
            class: "scroll__title",
            innerHTML: _ctx.t(`Home.problems.cards[${index - 1}].title`)
          }, null, 8, _hoisted_6),
          _createElementVNode("div", {
            class: "scroll__description",
            innerHTML: _ctx.t(`Home.problems.cards[${index - 1}].description`)
          }, null, 8, _hoisted_7),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("img", {
              src: 
              require(`@/assets/cards/problems/${_ctx.t(
                `Home.problems.cards[${index - 1}].image`
              )}_mini.png`)
            ,
              alt: _ctx.t(`Home.problems.cards[${index - 1}].alt`)
            }, null, 8, _hoisted_9)
          ])
        ], 40, _hoisted_5)
      }), 64))
    ])
  ], 32))
}