
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  setup() {
    const { t } = useI18n();

    const team = [
      "Alena",
      "Alexander",
      "Anna",
      "Dmitry",
      "Fedor",
      "Natalia",
      "Platon",
      "Ulyana",
    ];

    const randomMember = team[Math.floor(Math.random() * team.length)];

    return {
      t,
      randomMember,
    };
  },
});
