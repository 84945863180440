
import { defineComponent, onMounted, onUnmounted, Ref, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";

export default defineComponent({
  setup() {
    const route = useRoute();
    const openModal = ref(false);
    const { t, availableLocales, locale } = useI18n();
    const yOffset = -24;

    const lastScroll = ref(0);
    const defaultOffset = 100;
    const header: Ref<null | HTMLElement> = ref(null);

    const mouseEnterHandler = () => {
      if (header.value) {
        header.value.classList.remove("hide");
        if (window.pageYOffset >= window.screen.height) {
          setTimeout(function () {
            header.value!.classList.add("hide");
          }, 5000);
        }
      }
    };
    const scrollHandler = () => {
      if (header.value) {
        const containHide = header.value.classList.contains("hide");
        const scrollPosition =
          window.pageYOffset || document.documentElement.scrollTop;
        if (
          scrollPosition > lastScroll.value &&
          !containHide &&
          scrollPosition > defaultOffset
        ) {
          //scroll down
          header.value.classList.add("hide");
        } else if (scrollPosition < lastScroll.value && containHide) {
          //scroll up
          header.value.classList.remove("hide");
        }
        lastScroll.value = scrollPosition;
      }
    };

    const switchLang = () => {
      locale.value =
        locale.value == availableLocales[0]
          ? availableLocales[1]
          : availableLocales[0];

      localStorage.setItem("inkast:selectedLanguage", locale.value);
    };

    const scrollIntoView = (hash: string) => {
      openModal.value = false;
      const element = document.querySelector(hash);
      if (element) {
        const y =
          element?.getBoundingClientRect().top + window.scrollY + yOffset;

        window.scrollTo({ top: y, behavior: "smooth" });
      }
    };

    onMounted(() => {
      document.addEventListener("scroll", scrollHandler);
    });

    onUnmounted(() => {
      document.removeEventListener("scroll", scrollHandler);
    });

    return {
      openModal,
      switchLang,
      t,
      scrollIntoView,
      route,
      header,
      mouseEnterHandler,
    };
  },
});
