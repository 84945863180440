
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  setup() {
    const { t } = useI18n();

    const currentProblem = ref(0);
    const scrollHandler = (event: Event) => {
      document.querySelectorAll("[data-problem-id]").forEach((item) => {
        const el = event.target as HTMLElement;
        const elHeight = item.clientHeight;
        const elId = Number(item.getAttribute("data-problem-id"));
        const elTop = el.scrollTop;
        if (elTop >= elHeight * elId) {
          currentProblem.value = elId;
        }
      });
    };
    return {
      t,
      currentProblem,
      scrollHandler,
    };
  },
});
