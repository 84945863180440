import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e52fcfb6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "next" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "next__wrapper" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h2", {
      class: "next__header",
      innerHTML: _ctx.t('Home.next.title')
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: "next__description",
        innerHTML: _ctx.t('Home.next.description')
      }, null, 8, _hoisted_4),
      _createElementVNode("img", {
        src: require(`@/assets/cards/next/${_ctx.t('Home.next.image')}.png`),
        alt: "Кто такой соулмейт"
      }, null, 8, _hoisted_5)
    ])
  ]))
}