import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-393a0c76"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home__easy" }
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "easy__wrapper" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createElementVNode("h2", {
      class: "easy__header",
      innerHTML: _ctx.t('Home.notEasy.title')
    }, null, 8, _hoisted_2),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: "easy__title",
        innerHTML: _ctx.t('Home.notEasy.additional')
      }, null, 8, _hoisted_4),
      _createElementVNode("div", {
        class: "easy__description",
        innerHTML: _ctx.t('Home.notEasy.description')
      }, null, 8, _hoisted_5),
      _createElementVNode("img", {
        class: "easy__illustration",
        src: require('@/assets/cards/easy/illustration.png'),
        alt: "Найти лучшего друга"
      }, null, 8, _hoisted_6)
    ])
  ]))
}