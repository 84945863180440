
import { defineComponent, onMounted, watchEffect } from "vue";
import { event } from "vue-gtag";
import { useI18n } from "vue-i18n";

export default defineComponent({
  setup() {
    const { locale, t } = useI18n();
    const lang =
      localStorage.getItem("inkast:selectedLanguage") || navigator.language;
    if (/^[a-z][a-z]-[A-Z][A-Z]/.test(lang)) {
      locale.value = lang;
    }

    watchEffect(() => {
      document.title = t("title");
    });

    onMounted(() => {
      event("Visit");
    });
  },
});
