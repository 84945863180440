import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e33e490a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "home" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navbar = _resolveComponent("Navbar")!
  const _component_Greeting = _resolveComponent("Greeting")!
  const _component_Selection = _resolveComponent("Selection")!
  const _component_Couple = _resolveComponent("Couple")!
  const _component_Intrestings = _resolveComponent("Intrestings")!
  const _component_Rare = _resolveComponent("Rare")!
  const _component_HowItWorks = _resolveComponent("HowItWorks")!
  const _component_NotEasy = _resolveComponent("NotEasy")!
  const _component_Next = _resolveComponent("Next")!
  const _component_Problems = _resolveComponent("Problems")!
  const _component_Show = _resolveComponent("Show")!
  const _component_Team = _resolveComponent("Team")!
  const _component_Follow = _resolveComponent("Follow")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Navbar),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Greeting),
      _createVNode(_component_Selection),
      _createVNode(_component_Couple),
      _createVNode(_component_Intrestings),
      _createVNode(_component_Rare),
      _createVNode(_component_HowItWorks),
      _createVNode(_component_NotEasy),
      _createVNode(_component_Next),
      _createVNode(_component_Problems),
      _createVNode(_component_Show),
      _createVNode(_component_Team),
      _createVNode(_component_Follow),
      _createVNode(_component_Footer)
    ])
  ], 64))
}