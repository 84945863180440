{
  "title": "Inkast • Твой человек где-то рядом",
  "Home": {
    "nav": {
      "product": "Продукт",
      "team": "Команда",
      "invest": "Инвесторам",
      "donate": "Помочь проекту",
      "language": "EN",
      "join": "Присоединиться",
      "support": "Поддержка"
    },
    "greeting": {
      "hello": "Привет! <br> Это&nbsp;Inkast",
      "slogan": "Cоциальная сеть для поиска&nbsp;соулмейта"
    },
    "selection": {
      "title": "Подбираем людей по&nbsp;интересам, целям, любимым занятиям и&nbsp;даже характеру",
      "join": "Присоединиться"
    },
    "couple": {
      "title": "Где-то на&nbsp;земле существует человек, который создан именно для тебя",
      "additional": "И&nbsp;вы&nbsp;еще не&nbsp;знаете о&nbsp;существовании друг друга"
    },
    "intrestings": {
      "title": "Представьте, <br> может быть, вы&nbsp;оба...",
      "additional": "Интересов",
      "bubles": [
        {
          "image": "cube",
          "text": "Фанаты настольных игр"
        },
        {
          "image": "notebook",
          "text": "Кодите по ночам"
        },
        {
          "image": "gamepad",
          "text": "Играете в компьютерные игры"
        },
        {
          "image": "instruments",
          "text": "Много работаете "
        },
        {
          "image": "palette",
          "text": "Мечтаете стать художниками"
        },
        {
          "image": "music",
          "text": "Любите одинаковые песни"
        },
        {
          "image": "astrology",
          "text": "Увлекаетесь астрологией"
        },
        {
          "image": "anime",
          "text": "Смотрите по ночам аниме"
        },
        {
          "image": "run",
          "text": "Увлекатесь бегом"
        },
        {
          "image": "freedom",
          "text": "Мечтаете побывать в Нью-Йорке"
        },
        {
          "image": "boom",
          "text": "Без ума от Наруто"
        },
        {
          "image": "corgi",
          "text": "Воспитываете корги"
        },
        {
          "image": "china",
          "text": "Увлекаетесь китайским"
        },
        {
          "image": "apple",
          "text": "Инвестируете в Apple"
        },
        {
          "image": "cash",
          "text": "Любите деньги"
        },
        {
          "image": "needle",
          "text": "Покупаете нашивки в Daijebu"
        },
        {
          "image": "detective",
          "text": "Пересматриваете Шерлока на Новый год"
        },
        {
          "image": "meditation",
          "text": "Медитируете"
        },
        {
          "image": "lotus",
          "text": "Занимаетесь йогой"
        },
        {
          "image": "badminton",
          "text": "Играете в бадминтон"
        },
        {
          "image": "abacus",
          "text": "Не можете решить уравнение Навье-Стокса"
        },
        {
          "image": "notes",
          "text": "Не любите сольфеджио"
        },
        {
          "image": "tiktok",
          "text": "Снимаете ролики в TikTok"
        },
        {
          "image": "starsky",
          "text": "Пытаетесь разгадать загадки космоса"
        },
        {
          "image": "magnifier",
          "text": "Расследуете теории заговора"
        },
        {
          "image": "alembic",
          "text": "Занимаетесь протеомикой"
        },
        {
          "image": "icecream",
          "text": "Не любите сладкое"
        },
        {
          "image": "donut",
          "text": "Любите сладкое"
        },
        {
          "image": "teapot",
          "text": "Пьете чай без сахара"
        },
        {
          "image": "potato",
          "text": "Тащитесь от чипсов"
        },
        {
          "image": "chocolate",
          "text": "Покупаете сникерсы коробками"
        },
        {
          "image": "leaf",
          "text": "Имеете дома коробку с лекарствами"
        },
        {
          "image": "popcorn",
          "text": "Пытаетесь всех подсадить на «Атаку титанов»"
        },
        {
          "image": "bag",
          "text": "Любите спонтанные поездки"
        },
        {
          "image": "bento",
          "text": "Тратите последние деньги на роллы"
        },
        {
          "image": "thread",
          "text": "Шьёте одежду"
        },
        {
          "image": "skull",
          "text": "Занимаетесь экстремальными видами спорта"
        },
        {
          "image": "snowboard",
          "text": "Каждую зиму катаетесь на сноуборде"
        },
        {
          "image": "work",
          "text": "Участвуете в хакатонах "
        },
        {
          "image": "books",
          "text": "Пишете книги"
        },
        {
          "image": "soap",
          "text": "Варите мыло "
        },
        {
          "image": "tvbox",
          "text": "Смотрите видео с депиляцией "
        },
        {
          "image": "skateboard",
          "text": "Ездите на скейтборде "
        },
        {
          "image": "smile",
          "text": "Мечтаете ничего не делать"
        },
        {
          "image": "friedegg",
          "text": "Изучаете высокую кулинарию"
        },
        {
          "image": "borido",
          "text": "Любите борщ со сметаной"
        },
        {
          "image": "paella",
          "text": "Цель жизни — вкусно покушать"
        },
        {
          "image": "parachute",
          "text": "Мечтаете прыгнуть с парашютом"
        },
        {
          "image": "wolf",
          "text": "Мечтаете жить в доме с большой собакой"
        },
        {
          "image": "museum",
          "text": "Наслаждаетесь архитектурой"
        },
        {
          "image": "pickaxe",
          "text": "Любите поиграть в майнкрафт"
        },
        {
          "image": "star",
          "text": "Любите поиграть в бравл старс"
        },
        {
          "image": "pierog",
          "text": "Можете ежедневно питаться хинкалями и хачапури"
        },
        {
          "image": "kitty",
          "text": "Любите кошек"
        },
        {
          "image": "sunrise",
          "text": "Любите гулять в солнечную погоду"
        },
        {
          "image": "subway",
          "text": "Любите кататься на метро"
        },
        {
          "image": "walk",
          "text": "Можете пройти 40 километров за один день"
        },
        {
          "image": "scooter",
          "text": "Любите кататься на самокате"
        },
        {
          "image": "incredible",
          "text": "Пытаетесь пережить культурный шок"
        },
        {
          "image": "box",
          "text": "Имеете дома пакет с пакетами"
        },
        {
          "image": "hanafuda",
          "text": "Хотите разложить кого-нибудь на таро"
        },
        {
          "image": "theatre",
          "text": "Любите театр"
        },
        {
          "image": "postlove",
          "text": "Мечтаешь быть css к его html"
        },
        {
          "image": "kiss",
          "text": "Знаете, что значит Гамарджоба"
        },
        {
          "image": "python",
          "text": "Любите Python всей душой"
        },
        {
          "image": "c-plus-plus",
          "text": "Не любите С++"
        },
        {
          "image": "snowflake",
          "text": "Любите кататься на коньках "
        },
        {
          "image": "faceinclouds",
          "text": "Вместе ходитe к психологу"
        },
        {
          "image": "brain",
          "text": "Вместе ходитe к психиатру"
        },
        {
          "image": "shower",
          "text": "Любите петь в душе"
        },
        {
          "image": "wine",
          "text": "Любите дорогой алкоголь"
        },
        {
          "image": "moai",
          "text": "Пытаетесь найти смысл жизни"
        },
        {
          "image": "tea",
          "text": "Пьёте по 6 кружек кофе в день"
        },
        {
          "image": "note",
          "text": "Сидели на BeOn"
        },
        {
          "image": "game",
          "text": "Играете в ролевые игры"
        },
        {
          "image": "melody",
          "text": "Фанатели по Glee Cast"
        },
        {
          "image": "tape",
          "text": "Посмотрели почти все сериалы на Netflix"
        },
        {
          "image": "basketball",
          "text": "Несколько раз проходили Mass Effect"
        },
        {
          "image": "movie",
          "text": "Пишете обзоры на кино"
        },
        {
          "image": "desktop",
          "text": "Работаете дизайнерами"
        },
        {
          "image": "swap",
          "text": "Менеджерите задачки"
        },
        {
          "image": "city",
          "text": "Любите урбанистику"
        },
        {
          "image": "van",
          "text": "Катаетесь на трамвае"
        },
        {
          "image": "magister",
          "text": "Студенты вуза"
        },
        {
          "image": "shopping",
          "text": "Покупаете сахар"
        },
        {
          "image": "finances",
          "text": "Шарите за тренды"
        },
        {
          "image": "chartdecrease",
          "text": "Мониторите NFT рынок"
        },
        {
          "image": "bitcoin",
          "text": "Покупаете крипту"
        },
        {
          "image": "hole",
          "text": "Кладете плитку"
        },
        {
          "image": "taxi",
          "text": "Таксуете в экономе"
        },
        {
          "image": "headphones",
          "text": "Слушаете Оксимирона"
        },
        {
          "image": "piano",
          "text": "Пишете биты"
        },
        {
          "image": "banana",
          "text": "Любите бананы"
        },
        {
          "image": "sun",
          "text": "Ждете лето"
        },
        {
          "image": "car",
          "text": "Помните о замене масла"
        },
        {
          "image": "wheel",
          "text": "Любите качать шины"
        },
        {
          "image": "shoes",
          "text": "Реселлите кроссы"
        },
        {
          "image": "book",
          "text": "Читали Ницше"
        },
        {
          "image": "faucet",
          "text": "Можете починить кран"
        },
        {
          "image": "couch",
          "text": "Любите двигать мебель"
        }
      ]
    },
    "rare": {
      "title": "И&nbsp;лишь только редкий случай мог&nbsp;бы вас свести <br> Этот случай&nbsp;&mdash; <span>уже сейчас</span>",
      "additional": "Вы&nbsp;оба сочетаете в&nbsp;себе многогранную комбинацию разных вкусов, интересов и&nbsp;взглядов на&nbsp;жизнь. Найти похожих людей очень трудно, но&nbsp;для нас это не&nbsp;проблема"
    },
    "howItWorks": {
      "title": "Как это работает?",
      "additional": "Листайте ленту с&nbsp;разнообразным контентом, лайкайте понравившиеся <span style='color: #C06CE8'>мемы, фильмы, книги, компьютерные игры, любимую музыку...</span>категорий контента много, и&nbsp;они постоянно пополняются!",
      "description": "Мы&nbsp;стараемся учитывать даже самые редкие интересы, чтобы ты&nbsp;точно нашел соулмейта. Но&nbsp;если все&nbsp;же твоего интереса нет&nbsp;&mdash; напиши нам об&nbsp;этом в&nbsp;<a href=\"https://t.me/inkast_me/511\" target=\"_blank\" style=\"color: #C06CE8; text-decoration: none\">Телеграм!</a>"
    },
    "notEasy": {
      "title": "Не&nbsp;всё так просто",
      "additional": "Мы&nbsp;разработали собственные алгоритмы машинного обучения, которые создают ваших цифровых двойников, приписывая им&nbsp;определенные интересы и&nbsp;характер",
      "description": "Цифровые двойники сравниваются дополнительными алгоритмами и&nbsp;прогнозируются наилучшие комбинации людей для вас"
    },
    "algorithm": {
      "title": "Работа алгоритма",
      "step-1": "Создается цифровая копия человека на&nbsp;основе его метаданных.<br> <br> Каждая копия обладает вашими интересами и&nbsp;характером",
      "step-2": "Алгоритмы машинного обучения сопоставляют цифровые портреты разных людей, образуя матрицу наилучших комбинаций по&nbsp;интересам и&nbsp;характеру",
      "step-3": "Наш сервис показывает вам список людей-соулмейтов"
    },
    "next": {
      "title": "Что дальше?",
      "description": "Соулмейт&nbsp;&mdash; это человек, который по&nbsp;духу подходит именно вам. С&nbsp;ним формируется особенно крепкая связь. Такой человек может быть для вас лучшим другом, возлюбленным, партнёром, самым интересным собеседником. <br> <br>Ваша крепкая связь будет обусловлена множеством совпадающих интересов, вглядов на&nbsp;жизнь, одинаковому мировоззрению. Если с&nbsp;большинством людей ваши пути по&nbsp;жизни могут расходиться, то&nbsp;с&nbsp;соулмейтом вы&nbsp;двигаетесь по&nbsp;жизни рядом. Именно поэтому такая связь для людей является одной из&nbsp;самых больших ценностей.",
      "image": "ru"
    },
    "problems": {
      "title": "Мы&nbsp;даём возможность решить актуальные проблемы",
      "cards": [
        {
          "title": "Отсутствие времени",
          "image": "time",
          "alt": "Как знакомиться при отсутствии времени",
          "description": "Если у&nbsp;вас нет времени на&nbsp;знакомства и&nbsp;вы&nbsp;много работаете, в&nbsp;нашем сервисе вы&nbsp;найдёте себе человека с&nbsp;таким&nbsp;же ритмом и&nbsp;складом жизни"
        },
        {
          "title": "Темы для разговоров",
          "image": "message",
          "alt": "Темы для разговоров на свидании",
          "description": "Вам не&nbsp;придётся придумывать темы для разговоров, вас уже подобрали максимально похожими по&nbsp;интересам"
        },
        {
          "title": "Одиночество",
          "image": "loneliness",
          "alt": "Как справиться с одиночеством",
          "description": "Даже имея много друзей, можно чувствовать себя одиноким, не&nbsp;находя в&nbsp;окружении собеседников по&nbsp;вашим интересам. Наш сервис найдёт человека с&nbsp;таким&nbsp;же мировоззрением, как и&nbsp;у&nbsp;вас"
        },
        {
          "title": "Поиск друзей",
          "image": "friends",
          "alt": "Как найти друзей",
          "description": "В&nbsp;сервисах для знакомств вам часто пишут с&nbsp;романтическими предложениями. Наши системы статусов сразу покажут, что ищет человек: друзей, любимого человека, либо всё вместе"
        }
      ],
      "join": "Присоединиться"
    },
    "show": {
      "title": "Inkast знает этого человека и&nbsp;готов показать его вам",
      "join": "Найти друга"
    },
    "team": {
      "title": "Команда",
      "interactive": "Интерактив",
      "members": [
        {
          "avatar": "Alena.png",
          "emoji": "paw",
          "name": "Алёна Самарина",
          "post": "CEO и Head Marketing",
          "preferredColor": "FFE5D6",
          "description": "Выполняла проекты для Mars, Danone, Nexign, Schlumberger, Газпром Нефть, Северсталь, Росатом <br><br> Неоднократный победитель кейс-чемпионатов и&nbsp;хакатонов",
          "employmentExp": "Опыт работы: 6&nbsp;лет",
          "link": "Напишите нам: info&#64;inkast.me"
        },
        {
          "avatar": "Alexander.png",
          "emoji": "trackball",
          "name": "Александр Грин",
          "post": "COO и&nbsp;Продуктовый дизайнер",
          "preferredColor": "E7E7E9",
          "description": "Выполнял проекты для Яндекса, VK, Газпром, Skyeng и&nbsp;X5&nbsp;Group <br><br> Призер олимпиады ВШЭ в&nbsp;области дизайна и&nbsp;неоднократный победитель хакатонов",
          "employmentExp": "Опыт работы: 6&nbsp;лет",
          "link": "Напишите нам: info&#64;inkast.me"
        },
        {
          "avatar": "Natalia.png",
          "emoji": "noevilmonkey",
          "name": "Наталья Мальцева",
          "post": "CTO и&nbsp;Backend разработчик",
          "preferredColor": "E0C49E",
          "description": "Строила гоночный болид в&nbsp;отделе электрики<br><br> Получатель гранта УМНИК. Победитель хакатонов и&nbsp;кейс-чемпионатов",
          "employmentExp": "Опыт работы: 5&nbsp;лет",
          "link": "Напишите нам: info&#64;inkast.me"
        },
        {
          "avatar": "Platon.png",
          "emoji": "vulcan",
          "name": "Лапп Платон",
          "post": "Frontend разработчик",
          "preferredColor": "C7C789",
          "description": "Просто хороший парень",
          "employmentExp": "Опыт работы: 5&nbsp;лет",
          "link": "Напишите нам: info&#64;inkast.me"
        },
        {
          "avatar": "Ulyana.png",
          "emoji": "tiger",
          "name": "Ульяна Саламатова",
          "post": "Android разработчик",
          "preferredColor": "E6F7A2",
          "description": "Пишу на&nbsp;Kotlin",
          "employmentExp": "Опыт работы: 3&nbsp;года",
          "link": "Напишите нам: info&#64;inkast.me"
        },
        {
          "avatar": "Anna.png",
          "emoji": "victory",
          "name": "Анна Грохотова",
          "post": "Маркетолог",
          "preferredColor": "FFE5D6",
          "description": "Выполняю проекты в сфере нейротехнологий <br><br> Знаю, как работает наш мозг и&nbsp;что нужно людям",
          "employmentExp": "Опыт работы: 5&nbsp;лет",
          "link": "Напишите нам: info&#64;inkast.me"
        },
        {
          "avatar": "NataliaT.png",
          "emoji": "smile",
          "name": "Наталья Трусова",
          "post": "Аналитик данных",
          "preferredColor": "94ADE8",
          "description": "Участие во&nbsp;многих проектах для крупных фирм в&nbsp;составе консалтинговой команды, работа в&nbsp;финансовой организации и&nbsp;разработка новых алгоритмов, помогающих бизнесу развиваться",
          "employmentExp": "Опыт работы: 3&nbsp;года",
          "link": "Напишите нам: info&#64;inkast.me"
        },
        {
          "avatar": "Sofia.png",
          "emoji": "sparkles",
          "name": "Болоховская Софья",
          "post": "SMM-менеджер, маркетолог",
          "preferredColor": "F8DFBE",
          "description": "Выполняла проекты для консалтингового агентства, крупного промышленного предприятия и&nbsp;розничных сетей<br><br>Имеет высшее образование в&nbsp;сфере Рекламы и&nbsp;связей с&nbsp;общественностью",
          "employmentExp": "Опыт работы: 2&nbsp;года",
          "link": "Напишите нам: info&#64;inkast.me"
        },
        {
          "avatar": "Alan.png",
          "emoji": "goggles",
          "name": "Алан Гасиев",
          "post": "Занимаюсь контролем качества продуктов",
          "preferredColor": "f7f7f7",
          "description": "Поиск дефектов, установка требований к&nbsp;системе и&nbsp;анализ её&nbsp;поведения",
          "employmentExp": "Опыт работы: 3&nbsp;года",
          "link": "Напишите нам: info&#64;inkast.me"
        },
        {
          "avatar": "Andrey.png",
          "emoji": "winking-face",
          "name": "Андрей Шабунин",
          "post": "Android разработчик",
          "preferredColor": "EDECE3",
          "description": "Трудоголик по жизни<br><br>Пишу на Kotlin/Java/C++",
          "employmentExp": "Опыт работы: 3&nbsp;года",
          "link": "Напишите нам: info&#64;inkast.me"
        },
        {
          "avatar": "Misha.png",
          "emoji": "microscope",
          "name": "Миша Санжак",
          "post": "DevOps",
          "preferredColor": "CED6E8",
          "description": "Пишу много кода и не только yaml. Люблю механические клавиатуры, становую тягу и системы",
          "employmentExp": "Опыт работы: больше 6&nbsp;лет",
          "link": "Contact us: info&#64;inkast.me"
        }
      ]
    },
    "follow": {
      "title": "Будем <br> друзьями!",
      "additional": "Подпишитесь на&nbsp;обновления, чтобы не&nbsp;пропустить запуск",
      "inputs": {
        "name": "Имя",
        "nameError": "Введите имя",
        "email": "Почта",
        "emailError": "Некорректный адрес почты, повторите попытку",
        "confPolicy": "Я&nbsp;согласен&nbsp;с <a href='/privacy' target='_blank'>политикой конфиденциальности</a>",
        "confError": ""
      },
      "popup": {
        "title": "Спасибо за&nbsp;подписку на&nbsp;обновления",
        "description": "Обещаем не&nbsp;спамить и&nbsp;держать только в&nbsp;курсе актуальных событий"
      },
      "join": "Присоединиться"
    },
    "footer": {
      "with": "С ",
      "love": " из Санкт-Петербурга",
      "termsAndPolicy": "Условия и&nbsp;Политика"
    }
  },
  "Donate": {
    "title": "Поддержать проект",
    "problems": "Возникли проблемы?",
    "variants": {
      "viaInternet": {
        "title": "Безналичный перевод",
        "description": "На&nbsp;совместный счёт Алёны Самариной<br>и&nbsp;команды Inkast"
      },
      "vk": {
        "title": "Стать VK&nbsp;Доном",
        "description": "Оформите ежемесячное пожертвование через&nbsp;VK donut. Это поможет нашему развитию"
      }
    }
  },
  "Investors": {
    "hero": {
      "CEO": "Алёна Самарина",
      "CTO": "Наталья Мальцева",
      "COO": "Александр Грин",
      "contacts": "Напишите нам"
    },
    "rootcase": {
      "title": "Первопричина",
      "leadtext": "Изначально идея Инкаста возникла из&nbsp;наблюдения, что люди в&nbsp;сервисах знакомств не&nbsp;могут найти себе партнера для долговечных отношений",
      "text": "Была выдвинута гипотеза, что существующие на&nbsp;рынке предложения не&nbsp;закрывают потребность в&nbsp;нахождении пары. Чтобы проверить данное суждение, был проведен опрос",
      "button": "Открыть&nbsp;презентацию&nbsp;&bull;&nbsp;RU"
    },
    "mvp": {
      "mvpmap": {
        "image": "ru"
      },
      "footnote": "Часть функций является конфиденциальными и&nbsp;в&nbsp;списках не&nbsp;указываются",
      "legenda01": "MVP",
      "legenda02": "Улучшить после MVP"
    },
    "conclusions": {
      "title": "Выводы",
      "description": "Inkast&nbsp;&mdash; сервис, которому еще нет аналогов на&nbsp;рынке. Он&nbsp;поможет: <ul><li>большинству людей найти свою половинку, друга или собеседника с&nbsp;похожими интересами</li><li>помочь в&nbsp;решении проблем современности, связанных с&nbsp;отношениями</li></ul>"
    }
  }
}
